import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Divider as MuiDivider } from '@mui/material';
import Section from "../../dynamic/Section";
import { Helmet } from "react-helmet-async";
import pageConfigData from './pageConfig.json';
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const Divider = styled(MuiDivider)(spacing);

function App() {
  const [pageConfig] = useState(pageConfigData);
  const [loadingPage, setLoadingPage] = useState(true);

  async function apiData(rowsPages, pageNumbers, nome) {}

  useEffect(() => {
    apiData().catch((err) => {
      console.log(err);
    }).finally(() => setLoadingPage(false));
  }, []);

  if (!pageConfig) return <Typography>Caricamento...</Typography>;

  return (
    <React.Fragment>
      <Helmet title={pageConfig.pageTitle} />
      {loadingPage ? (
        <Box sx={{width: "calc(100vw - 40px)", display: "flex",justifyContent: "center",}}><CircularProgress /></Box>
    ):(      
      <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          {pageConfig.pageTitle}
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />
      
    {pageConfig.sections.map((section, index) => (
        <Section key={index} section={section} />
      ))}
      </React.Fragment>
    )}
      
    </React.Fragment>
  );
}

export default App;