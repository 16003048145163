// DynamicBox.js
import React from "react";
import { Box, Typography, Grid, Card as MuiCard, CardContent, useMediaQuery, Chip } from "@mui/material";
import styled from "@emotion/styled";
import * as Icons from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

function DynamicBox({ boxConfig }) {
  const hiddenOn = boxConfig.hiddenOn || {};

  // Rileva i breakpoint per nascondere il box
  const isHiddenXs = useMediaQuery((theme) => theme.breakpoints.only("xs")) && hiddenOn.xs;
  const isHiddenSm = useMediaQuery((theme) => theme.breakpoints.only("sm")) && hiddenOn.sm;
  const isHiddenMd = useMediaQuery((theme) => theme.breakpoints.only("md")) && hiddenOn.md;
  const isHiddenLg = useMediaQuery((theme) => theme.breakpoints.only("lg")) && hiddenOn.lg;
  const isHiddenXl = useMediaQuery((theme) => theme.breakpoints.only("xl")) && hiddenOn.xl;

  // Nascondi il box se uno dei breakpoint impostati è attivo
  if (isHiddenXs || isHiddenSm || isHiddenMd || isHiddenLg || isHiddenXl) {
    return null;
  }

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {boxConfig.title}
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: 3 }}>
          {boxConfig.contents.map((content, index) => {
            const IconComponent = Icons[content.icon] || null;

            return (
              <Grid item xs={12} sm={content.grid?.sm || 6} key={index}>
                <Box display="flex" alignItems="center">
                  {IconComponent && <IconComponent style={{ marginRight: 8 }} />}
                  {content.label ? (
                    <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
                      {content.label}: 
                    </Typography>
                  ) : null}

                  {/* Mostra come chip solo se il campo chip è presente */}
                  {content.chip ? (
                    <Chip 
                      label={content.value}
                      size ="small"
                      color = {content.chip}
                      sx={{
                        fontWeight: "bold",
                      }}
                      
                    />
                  ) : (
                    <Typography variant="body2" sx={{ textAlign: content.textAlign || 'left' }}>
                      {content.value}
                    </Typography>
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DynamicBox;
