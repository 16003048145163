import React, { useState } from "react";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  Grid,
  Card as MuiCard,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 5;
const CustomSelectMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: {
        xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
        sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
      },
    },
  },
};

const Card = styled(MuiCard)(spacing);

function DynamicForm({ formConfig, eventHandlers = {} }) {
  const token = sessionStorage.getItem("token");
  const initializeFormData = () => {
    const initialData = {};
    formConfig.fields.forEach((field) => {
      initialData[field.name] = field.value || "";
    });
    return initialData;
  };

  const [formData, setFormData] = useState(initializeFormData());
  const [errors, setErrors] = useState({});
  const [fieldOptions, setFieldOptions] = useState({}); // Stato per opzioni dinamiche
  const [expanded, setExpanded] = useState(formConfig.accordion?.expanded || false);

  const handleAccordionChange = () => setExpanded(!expanded);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    console.log(111111111)
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    const field = formConfig.fields.find((f) => f.name === name);
    if (field && field.validation && field.validation.regex) {
      const regex = new RegExp(field.validation.regex);
      if (!regex.test(value)) {
        setErrors((prev) => ({
          ...prev,
          [name]: field.validation.errorMessage,
        }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    }
    console.log(2222222)
    console.log(field)
    // Gestisci l'evento onChange se è definito nel JSON e richiama handleEventAction
    if (field && field.events && field.events.onChange) {
      console.log(field.events.onChange)
      await handleEventAction(field.events.onChange, value);
    }

    // Chiamata al gestore evento se specificato
    if (field && field.onChange && eventHandlers[field.onChange]) {
      console.log(field.onChange)
      eventHandlers[field.onChange](value);
    }
  };

  const handleEventAction = async (eventConfig, value) => {
    const { action, endpoint, data, updateField } = eventConfig;

    if (action === "fetchData" && endpoint) {
      try {
        const resolvedParams = { ...data };
        Object.keys(resolvedParams).forEach((key) => {
          if (resolvedParams[key] === "{value}") resolvedParams[key] = value;
        });

        const response = await axios.post(endpoint, { "token":token, data: resolvedParams });

        if (updateField) {
          setFieldOptions((prevOptions) => ({
            ...prevOptions,
            [updateField]: response.data.options // aggiorna opzioni per il campo target
          }));
          setFormData((prevData) => ({
            ...prevData,
            [updateField]: "" // resetta valore del campo aggiornato
          }));
        }
      } catch (error) {
        console.error("Errore nella chiamata API:", error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    let newErrors = {};

    formConfig.fields.forEach((field) => {
      if (field.validation.required && !formData[field.name]) {
        valid = false;
        newErrors[field.name] = `${field.label} è obbligatorio.`;
      } else if (field.validation.regex) {
        const regex = new RegExp(field.validation.regex);
        if (!regex.test(formData[field.name])) {
          valid = false;
          newErrors[field.name] = field.validation.errorMessage;
        }
      }
    });

    setErrors(newErrors);

    if (valid) {
      console.log("Dati del form:", formData);
    }
  };

  const formContent = (
    <CardContent>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          {formConfig.fields.map((field, index) => (
            <Grid item key={index} xs={12} sm={field.grid?.sm || 12}>
              {field.type === "select" ? (
                <FormControl fullWidth variant="outlined">
                  <InputLabel shrink={true}>{field.label}</InputLabel>
                  <Select
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    error={Boolean(errors[field.name])}
                    MenuProps={CustomSelectMenuProps}
                  >
                    {(fieldOptions[field.name] || field.options).map((option, idx) => (
                      <MenuItem key={idx} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography color="error" variant="caption">
                    {errors[field.name]}
                  </Typography>
                </FormControl>
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  label={field.label}
                  name={field.name}
                  type={field.type === "textarea" ? "text" : field.type}
                  placeholder={field.placeholder}
                  required={field.validation.required}
                  InputProps={{
                    readOnly: !field.editable, 
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    minLength: field.validation.minLength,
                    pattern: field.validation.regex,
                    min: field.validation.min,
                    max: field.validation.max,
                  }}
                  multiline={field.type === "textarea"}
                  rows={field.type === "textarea" ? 4 : 1}
                  value={formData[field.name]}
                  onChange={handleChange}
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <Button variant="contained" color="primary" type="submit">
            {formConfig.submitButtonText}
          </Button>
        </Box>
      </Box>
    </CardContent>
  );

  return formConfig.accordion?.enabled ? (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" sx={{ ml: 2 }}>{formConfig.formTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>{formContent}</AccordionDetails>
    </Accordion>
  ) : (
    <Card mb={6}>
      <CardHeader title={formConfig.formTitle} />
      {formContent}
    </Card>
  );
}

export default DynamicForm;
