// Section.js
import React from "react";
import { Grid } from "@mui/material";
import DynamicForm from "./DynamicForm";
import DynamicTable from "./DynamicTable";
import TabsComponent from "./TabsComponent";
import DynamicChart from "./DynamicChart";
import DynamicDataGrid from "./DynamicDataGrid";
import DynamicBox from "./DynamicBox.js";
import axios from "axios";

function Section({ section }) {
  // Gestori di eventi per caricamento dinamico dei dati
  const handleSelectChange = async (value, updateField, endpoint) => {
    try {
      // Esempio di endpoint API per aggiornare un'altra select
      const response = await axios.post(endpoint, { data: { value } });
      const options = response.data.options;

      // Funzione che aggiorna il campo target passando le nuove opzioni
      updateField(options);
    } catch (error) {
      console.error("Errore durante il caricamento delle opzioni:", error);
    }
  };

  // Configura i gestori di eventi per il form
  const eventHandlers = {
    loadDynamicOptions: handleSelectChange, // Gestore per aggiornare dinamicamente una select
    // Aggiungi altri gestori di eventi specifici qui
  };

  const renderElement = (element, index) => {
    if (element.custom) {
      return (
        <div key={index}>
          <p>Elemento custom: {element.type}</p>
        </div>
      );
    }

    switch (element.type) {
      case "form":
        return <DynamicForm key={index} formConfig={element.config} eventHandlers={eventHandlers}/>;
      case "table":
        return <DynamicTable key={index} tableConfig={element.config} />;
      case "tabs":
        return <TabsComponent key={index} tabsConfig={element.config.tabs} />;
      case "chart":
        return <DynamicChart key={index} chartConfig={element.config} />;
      case "datagrid":
        return <DynamicDataGrid key={index} gridConfig={element.config} />;
      case "box":
        return <DynamicBox key={index} boxConfig={element.config} />;
      case "container":
        // Gestione di un container per layout annidato
        return (
          <Grid
            container
            key={index}
            direction={element.layout === "row" ? "row" : "column"}
            spacing={4}
          >
            {element.elements.map((nestedElement, nestedIndex) => (
              <Grid
                item
                key={nestedIndex}
                xs={(nestedElement.grid && nestedElement.grid.xs) || 12}
                sm={(nestedElement.grid && nestedElement.grid.sm) || 12}
                md={(nestedElement.grid && nestedElement.grid.md) || 12}
                lg={(nestedElement.grid && nestedElement.grid.lg) || 12}
                xl={(nestedElement.grid && nestedElement.grid.xl) || 12}
              >
                {renderElement(nestedElement, nestedIndex)}
              </Grid>
            ))}
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction={section.layout === "row" ? "row" : "column"}
      spacing={6}
    >
      {section.elements.map((element, index) => (
        <Grid
          item
          key={index}
          xs={(element.grid && element.grid.xs) || 12}
          sm={(element.grid && element.grid.sm) || 12}
          md={(element.grid && element.grid.md) || 12}
          lg={(element.grid && element.grid.lg) || 12}
          xl={(element.grid && element.grid.xl) || 12}
        >
          {renderElement(element, index)}
        </Grid>
      ))}
    </Grid>
  );
}

export default Section;
